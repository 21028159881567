import { createContext, useState, useEffect, useContext } from 'react';

import * as Sentry from "@sentry/react";


export const AuthContext = createContext({});


export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(null);
    const [token, setToken] = useState(null);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isValidating, setIsValidating] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage ] = useState();

  
    useEffect(() => {
      const storagedUser = localStorage.getItem('user');
      const storagedToken = localStorage.getItem('token');
  
      if (storagedToken && storagedUser) {
        setToken(JSON.parse(storagedToken));
        setUser(JSON.parse(storagedUser));        
      }
    }, []);
  

    // const login = (userData) => {
    //     return new Promise( (resolve) => {
    //         if(userData.username === 'anderson' && userData.password === 'anderson'){
    //             resolve(true);
    //         }else{
    //             resolve(false);
    //         }
    //     });
    // }

    async function Login(userData) {
        
        setIsValidating(true);


        

        Sentry.setUser({ email: userData.username });



        let token = btoa(userData.username + ':' + userData.password);

        const headers = { 'Authorization': 'Basic ' + token};    
        fetch('https://www.catalystbg.com.br/wp-json/catalystbg/v1/auth', {
            method: 'POST',
            headers: headers,
            })
            .then((response) => { 
                console.log(response);
                if(response.ok){
                    console.log('response.ok');
                    console.log('Credenciais validadas.');
                    // setIsError(false);
                    // setIsValidating(false);
                    //history.push("/home");
                }else{                
                    console.log('Login failed.');
                }
                response.json().then((data) => {

                    //Login ok
                    if(data.auth == true){
                        console.log(data);
                        
                        setIsError(false);
                        console.log('isError:' + isError);
                        setIsValidating(false);
                        
                        localStorage.clear(); //limpa qualquer resquício de dados aqui

                        //localStorage.setItem('isAuthed', true);
                        //localStorage.setItem('isAuthed', JSON.stringify(true));
                        localStorage.setItem('user', JSON.stringify( data.user_data ));
                        localStorage.setItem('token', JSON.stringify( token ));
                        setUser(data.user_data);  
                        setToken(token);

                        console.log(user);
                        console.log(token);
        
                        console.log('--> history.replace("/home");');
                        //history.replace("/home");
  
                      }else{ //login failed
                        console.log('data response');
                        console.log(data); 
                        setErrorMessage(data.message);
                        setIsError(true);
                        setIsValidating(false);
  
                        //localStorage.setItem('isAuthed', JSON.stringify(false));
                      }                 
                })
            })
            .catch((err) => {
                console.log('Error response');
                console.log(err.message);
                
            });

    }
  
    function Logout() {      
        console.log('logOut');    
        localStorage.clear();
        
        setUser(null);

        Sentry.setUser(null);


        window.location.replace('/');



    }
  
    return (
      <AuthContext.Provider
        value={
            { isAuthed: Boolean(user), 
                user, 
                Login, 
                Logout, 
                //login, 
                isSubmitted,
                isValidating,
                isError,
                errorMessage 
            }
        }
      >
        {children}
      </AuthContext.Provider>
    );
  };
  
  export function useAuth() {
    const context = useContext(AuthContext);
  
    return context;
  }