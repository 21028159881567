import { useEffect, useState } from 'react';

import Tag from './Tag';
import { IonButton, IonCard, IonCardContent, IonCardHeader } from '@ionic/react';
import { Link, useHistory } from 'react-router-dom';


const Card = ({ activity }) => {

    const history = useHistory();
    
    const startTime = new Date(activity.activity_check_in_time);
    const endTime = new Date(activity.activity_check_out_time);
    let duration = endTime - startTime;
    let minutes = Math.floor((duration/1000)/60);

    const categories = activity.activity_category;

    return (
        
      <div  className={`card-activity activity-${activity.ID}`} 
            //onClick={() => history.push("/home/newActivity")}
            onClick={() => {
                    localStorage.setItem('currentActivity', JSON.stringify(activity));
                    history.push(`/home/newActivity`);
                }
            }
            
      >

        {/* <IonCard routerLink="/home/newActivity" 
            // routerDirection="forward" 
            >
            <IonCardContent> sdjf aoisjdfopiaj f </IonCardContent>
        </IonCard> */}


        <div className="detalhes_data">
            <span className="start_end"> {startTime.getHours()}:{startTime.getMinutes() < 10 ? '0' : ''}{startTime.getMinutes()}  - {endTime.getHours()}:{endTime.getMinutes() < 10 ? '0' : ''}{endTime.getMinutes()} </span>
            <span className="dia"> {startTime.getDate() } de {startTime.toLocaleString([], { month: 'long' } )} </span>
        </div>

        <h2 className="titulo"> {activity.post_title} {activity.client?.name} </h2>
        
        <span className="detalhes_adicionais"> {minutes} minutos | {activity.activity_type?.name?.slice(0,3)}. </span>

        {/* //Para multiplas categorias
            <div className="categorias">        
            {
                categories && categories.map((activity_category) => {
                    return (
                    <Tag data={activity_category} key={activity_category.term_id} /> 
                    )
                })
            }
        </div> */}

        <div className="categorias">        
            
            <Tag data={categories} key={categories?.term_id} /> 
            
        </div>

        

      </div>
    );
  };
  
  export default Card;
  