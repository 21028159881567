import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

//https://vite-pwa-org.netlify.app/guide/auto-update.html
import { registerSW } from 'virtual:pwa-register'
registerSW({ immediate: true })
//

import { defineCustomElements } from '@ionic/pwa-elements/loader';

import { AuthProvider } from './AuthContext';


import * as Sentry from "@sentry/react";

Sentry.init({
  release: "app.catalystbg@1.1.18",
  dsn: "https://4091cbd6f47e7051114e182aca98681d@o4506005458452480.ingest.us.sentry.io/4507881100345344",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 1.0, // 0.1 This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});




// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);



const container = document.getElementById('root');
//const root = createRoot(container!);
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App /> 
    </AuthProvider>
  </React.StrictMode>
);