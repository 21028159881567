import React, { useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonTitle,
  IonSearchbar,
  IonToolbar,
  IonRadioGroup,
  IonRadio,
} from '@ionic/react';


function AppTypeahead(props) {
  const [filteredItems, setFilteredItems] = useState([...props.items]);
  const [workingSelectedValue, setWorkingSelectedValue] = useState(props.selectedItem);

  console.log('workingSelectedValue', workingSelectedValue);
//   const isChecked = (value) => {
//     return workingSelectedValue.find((item) => item === value) !== undefined;
//   };

  const cancelChanges = () => {
    const { onSelectionCancel } = props;
    if (onSelectionCancel !== undefined) {
      onSelectionCancel();
    }
  };

  const confirmChanges = () => {
    const { onSelectionChange } = props;
    if (onSelectionChange !== undefined) {
      onSelectionChange(workingSelectedValue);
    }
  };

  const searchbarInput = (ev) => {
    filterList(ev.target.value);
  };

  /**
   * Update the rendered view with
   * the provided search query. If no
   * query is provided, all data
   * will be rendered.
   */
  const filterList = (searchQuery) => {
    /**
     * If no search query is defined,
     * return all options.
     */
    if (searchQuery === undefined || searchQuery === null) {
      setFilteredItems([...props.items]);
    } else {
      /**
       * Otherwise, normalize the search
       * query and check to see which items
       * contain the search query as a substring.
       */
      const normalizedQuery = searchQuery.toLowerCase();
      setFilteredItems(
        props.items.filter((item) => {
          return item.name.toLowerCase().includes(normalizedQuery);
        })
      );
    }
  };

//   const checkboxChange = (ev) => {
//     const { checked, value } = ev.detail;

//     if (checked) {
//       setWorkingSelectedValues([...workingSelectedValues, value]);
//     } else {
//       setWorkingSelectedValues(workingSelectedValues.filter((item) => item !== value));
//     }
//   };

  const radioChange = (ev) => {
    const { value } = ev.detail;

      setWorkingSelectedValue(value);

  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={cancelChanges}>Cancelar</IonButton>
          </IonButtons>
          <IonTitle>{props.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={confirmChanges}>Definir</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar onIonInput={searchbarInput} placeholder="Buscar"></IonSearchbar>
        </IonToolbar>
      </IonHeader>

      <IonContent color="light" class="ion-padding">
        {/* <IonList id="modal-list" inset={true}>
          {filteredItems.map((item) => (
            <IonItem key={item.value}>
              <IonCheckbox value={item.value} checked={isChecked(item.value)} onIonChange={checkboxChange}>
                {item.text}
              </IonCheckbox>
            </IonItem>
          ))}
        </IonList> */}

        <IonRadioGroup
         onIonChange={radioChange}
         value={workingSelectedValue}
        >                            
                        
            {filteredItems.map((item) => (
                <IonItem key={item.term_id}>
                    <IonRadio value={item.name}> {item.name} </IonRadio>
                </IonItem>
            ))}                    
            
        </IonRadioGroup>

      </IonContent>
    </>
  );
}
export default AppTypeahead;