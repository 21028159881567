//import './Card.css';

import { useState, useEffect } from "react";
import { useIonViewWillEnter } from "@ionic/react";
import { useContext } from "react";

import { AuthContext } from "../AuthContext";

import Card from "./Card";
 
const ActivitiesList = ( {pendingActivities}) => {
    
    { if(!!!pendingActivities ) return <>Sem atividades no momento. Conferindo novamente em alguns instantes.</>}

    console.log('ActivitiesList.jsx/pendingActivities', pendingActivities);

    return (
      
        <div className="activities-container">

            <h1>Você tem {pendingActivities.length == 1 ? pendingActivities.length + ' atividade' : pendingActivities.length + ' atividades'}  </h1>

            {(!!pendingActivities && !!pendingActivities[0]?.ID) && pendingActivities.map((activity) => {
                return (                    
                    <Card activity={activity} key={activity.ID}/>
                );
            })}

        </div>
        
    );
  };
  
  export default ActivitiesList;
  