
import { useHistory } from 'react-router';


export default function NotFoundPage(){

    const history = useHistory();

    return(

        <>

            <div style={{
                'width': '100%',
                'height': '100%',
                'display': 'flex',
                'justify-content': 'center',
                'flex-direction':' column',
                'align-items': 'center',
            }}>
           
                <div className="e404">
                    Erro de roteamento: 404.
                </div> 


                <button
                    onClick={() => { 
                        history.push("/home");
                    }}>

                    Ir para a home

                </button>
            </div>
        </>
        
    )
}