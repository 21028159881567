import Tag from './Tag';
import Timer from './Timer';
import { IonButton, IonCard, IonCardContent, IonCardHeader } from '@ionic/react';
import { Link, useHistory } from 'react-router-dom';


const CurrentActivityCard = ({ activity }) => {

    const history = useHistory();
    
    if(!activity) return('');

    return (
        
      <div  className={`current-activity-card activity-${activity.ID}`} 
            //onClick={() => history.push("/home/newActivity")}
            onClick={() => {
                    history.push(`/home/OngoingActivity`);
                }
            }            
      >
        <div className="col">
            <span>Em andamento...</span>
            <h2 className="titulo"> {activity.post_title} </h2>
        </div>
        <div className="col">
            <div className="small_counter">
                <Timer />
            </div>
        </div>
        
      </div>
    );
  };
  
  export default CurrentActivityCard;
  