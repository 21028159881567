import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonButton, IonIcon, IonBackButton, IonList, IonItem, IonLabel, useIonViewWillEnter, useIonViewDidEnter } from '@ionic/react';
import { create, ellipsisHorizontal, ellipsisVertical, helpCircle, search, personCircle, star } from 'ionicons/icons';

import { Link, useHistory } from 'react-router-dom';

import { useState, useEffect } from "react";

import Card from '../components/Card';
import ActivitiesList from '../components/ActivitiesList';
import CurrentActivityCard from '../components/CurrentActivityCard';
import { useAuth } from '../AuthContext';

import useOnlineStatus from '../hooks/useOnlineStatus';
//import { useCounter, useUpdate } from '../hooks/useUpdate';
import { useUpdate } from '../hooks/useUpdate';

//import { updateActivity, processActivityPullStack, appendItemToLocalStorage, removeItemFromLocalStorageByID } from '../hooks/functions';

const Home = () => {

  //const [pendingActivities, setPendingActivities] = useState([]);
  const {pendingActivities, updatePendingActivities, token} = useUpdate();

  //const counter = useCounter();

  const history = useHistory();

  const {Logout} = useAuth();
  
  const [currentOngoingActivity, setCurrentOngoingActivity] = useState();

  const [user] = useState(JSON.parse(localStorage.getItem('user')));

  
  useIonViewWillEnter(() => {

    // //const headers = { 'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2NhdGFseXN0YmcubG9jYWwiLCJpYXQiOjE2OTA1MTA2NjAsIm5iZiI6MTY5MDUxMDY2MCwiZXhwIjoxNjkxMTE1NDYwLCJkYXRhIjp7InVzZXIiOnsiaWQiOiIxIn19fQ.nRwVC9U2eRdxfa8CinjvpVJYjKRjakOXXMVBDVflrf0' };    
    // const headers = { 'Authorization': 'Basic ' + btoa('anderson:!C88a70a20!') };    
    
    // Promise.all([
    //   fetch('https://www.catalystbg.com.br/wp-json/catalystbg/v1/all_tax_terms', { method: 'GET', headers: headers }),
    //   fetch('https://www.catalystbg.com.br/wp-json/catalystbg/v1/activity', { method: 'GET', headers: headers }),
    // ])
    //   .then(([resTaxTerms, resActivities]) => 
    //     Promise.all([resTaxTerms.json(), resActivities.json()])
    //   )
    //   .then(([dataTaxTerms, dataActivities]) => {

    //     Object.entries(dataTaxTerms).map(
    //       (tax) => {
    //         //console.log(tax[0]);
    //         localStorage.setItem(tax[0], JSON.stringify(tax[1]) );
    //       }
    //     )

    //     localStorage.setItem('pendingActivities', JSON.stringify(dataActivities) );
    //     setPendingActivities( JSON.parse( localStorage.getItem('pendingActivities') ) );

    //     // console.log('pendingActivities from fetch');
    //     // console.log(pendingActivities);
    //     // console.log('pendingActivities from jsonparse');
    //     // console.log(JSON.parse( localStorage.getItem('pendingActivities') ));
        
        
    //   });

      //inicia processo de atualização

      updatePendingActivities();
      

      setCurrentOngoingActivity( JSON.parse( localStorage.getItem('currentOngoingActivity')) );
      
  });

  // useIonViewDidEnter(() => {
  //   console.log('useIonViewDidEnter');
  //     setCurrentOngoingActivity( JSON.parse( localStorage.getItem('currentOngoingActivity')) );
  //     setPendingActivities( JSON.parse( localStorage.getItem('pendingActivities') ) );

      
  //     console.log(pendingActivities);

      
  // });
  
  // const doLogOut = () => {
  //   Logout();
  // }

  const isOnline = useOnlineStatus();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle> Olá {user.display_name} | <span className="fs-small"> v1.1.18{isOnline ? 'on' : 'off'} </span> </IonTitle>
          <IonButtons slot="end">
              <IonButton onClick={() => Logout()}> <IonIcon slot="start" icon={personCircle}></IonIcon> Sair </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense"> 
          <IonToolbar>
            <IonTitle size="large">Home</IonTitle>
          </IonToolbar>
        </IonHeader>
        
        

        {/* <IonButton onClick={() => processActivityPullStack()}> <IonIcon slot="start" icon={personCircle}></IonIcon> Sair </IonButton>
         */}
        
        <CurrentActivityCard activity={currentOngoingActivity}/>

        <ActivitiesList pendingActivities={pendingActivities} />


      </IonContent>
    </IonPage>
  );
};

export default Home;
