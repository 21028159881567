import { useEffect, useState } from "react";
import { processActivityPullStack } from "./functions";

import { useAuth } from "../AuthContext";

// export function useCounter(){

//     const [counter, setCounter] = useState(0);

//     useEffect(() => {
        
//         const interval = setInterval(() => {
//             setCounter( counter + 1 );
//         }, 1000);

//         return () => clearInterval(interval);
//     }, [counter]);

//     return counter;
// }




export function useUpdate(){

    const [pendingActivities, setPendingActivities] = useState();
    const [token] = useState(localStorage.getItem('token'));
    const [user] = useState( JSON.parse( localStorage.getItem('user') ));
    

    const {Logout} = useAuth();

    useEffect( () => {

        const interval = setInterval(doUpdate, 15 * 1000);

        function doUpdate() {

            console.log('useUpdate yeah');
            console.log('user login', user.user_login);

            const headers = { 'Authorization': 'Basic ' + token};

            const status = 'aguardando';
            const dateStart = new Date().toISOString().substring(0, 10);
            const dateEnd = '2024-12-31';

            Promise.all([
                fetch('https://www.catalystbg.com.br/wp-json/catalystbg/v1/all_tax_terms', { method: 'GET', headers: headers }),
                //fetch('https://www.catalystbg.com.br/wp-json/catalystbg/v1/activity?professional=' + user.user_login + '&status=aguardando', { method: 'GET', headers: headers }),
                fetch('https://www.catalystbg.com.br/wp-json/catalystbg/v1/activity?status=' + status +'&professional=' + user.user_login + '&start=' + dateStart + '&end=' + dateEnd , { method: 'GET', headers: headers }),
                ])
                .then(([resTaxTerms, resActivities]) => 
                Promise.all([resTaxTerms.json(), resActivities.json()])
                )
                .catch((err) =>{
                    console.log('Erro no fetch:', err);
                    
                })
                .then(([dataTaxTerms, dataActivities]) => {

                    if(dataTaxTerms.code == 'incorrect_password'){
                        console.log('Senha incorreta.');
                        Logout();
                    }

                    console.log('dataTaxTerms', dataTaxTerms);
                    console.log('dataActivities', dataActivities);

                    Object.entries(dataTaxTerms).map(
                        (tax) => {
                        //console.log(tax[0]);
                        localStorage.setItem(tax[0], JSON.stringify(tax[1]) );
                        } 
                    )

                    localStorage.setItem('pendingActivities', JSON.stringify(dataActivities) );
                
                });

            setPendingActivities( JSON.parse( localStorage.getItem('pendingActivities') ) );

              
            processActivityPullStack();
        }
        
        //roda agora
        doUpdate();


        return () => clearInterval(interval);
    
    },[]);


    const updatePendingActivities = () => {
        console.log('updatePendingActivities yeah');
        setPendingActivities( JSON.parse( localStorage.getItem('pendingActivities') ) );
    }

    return {
        pendingActivities,
        updatePendingActivities,
        token
    };
}