import { useState, useEffect } from 'react';
import { isPlatform } from '@ionic/react';

import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
//import ImageTools from './ImageTools';
//const Compress = require('client-compress');

import Compress from 'client-compress';


export function useCamera() {

    const [photos, setPhotos] = useState([]);  
    const [currentOngoingPhotos, setCurrentOngoingPhotos] = useState(JSON.parse( localStorage.getItem('currentOngoingPhotos') ) || [] );
    const [currentOngoingCheckinPhoto, setCurrentOngoingCheckinPhoto] = useState();
    const [currentOngoingCheckoutPhotoMinute, setCurrentOngoingCheckoutPhotoMinute] = useState();
    const [currentOngoingCheckoutPhotoClient, setCurrentOngoingCheckoutPhotoClient] = useState();

    //const imageTools = new ImageTools();


    const options = {
        targetSize: 0.2,
        quality: 0.75,
        maxWidth: 800,
        maxHeight: 600
      }
      
      const compress = new Compress(options)



const takePhoto = async (photoType) => {

    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      //resultType: CameraResultType.DataUrl,
      //resultType: CameraResultType.Base64,
      source: CameraSource.Camera,
      quality: 70, //only ios and android
      // width: 600,
      // height: 400,
    });

    const newPhotos = [
      {
        photoType: photoType,
        imgData: "data:image/" + photo.format + ";base64," + photo.base64String,
      },
      ...photos,
    ];

    console.log(photo);
    // imageTools.resize(photo.webPath, { width: 480, height: 320 }).then((blob) => {
    //     // upload blob to server
    //     console.log(blob);
    //  })


    
    



    localStorage.setItem('currentOngoingPhotos', JSON.stringify(newPhotos) );

    setCurrentOngoingPhotos(newPhotos);

    setCurrentOngoingCheckinPhoto(
        JSON.parse( localStorage.getItem('currentOngoingPhotos') ).find(item => item.photoType === 'checkin') 
    );

    setCurrentOngoingCheckoutPhotoMinute(
        JSON.parse( localStorage.getItem('currentOngoingPhotos') ).find(item => item.photoType === 'checkout_minute') 
    );
    setCurrentOngoingCheckoutPhotoClient(
        JSON.parse( localStorage.getItem('currentOngoingPhotos') ).find(item => item.photoType === 'checkout_client') 
    );


     
    setPhotos(newPhotos);
  };



  const resize = async (target, photoType) => {
    
    //https://github.com/davejm/client-compress

    const options = {
        targetSize: 0.01,
        quality: 0.75,
        maxWidth: 300,
        maxHeight: 300
    }
      
    const compress = new Compress(options);
      
      
    const files = [...target.files]
    compress.compress(files).then((conversions) => {
    // Conversions is an array of objects like { photo, info }.
    // 'photo' has the photo data while 'info' contains metadata
    // about that particular image compression (e.g. time taken).

    const { photo, info } = conversions[0]

    console.log({ photo, info })

    // Create an object URL which points to the photo Blob data
    const objectUrl = URL.createObjectURL(photo.data);

    Compress.blobToBase64(photo.data).then( 
            (img) => { 

                let currentOngoingPhotos = JSON.parse(localStorage.getItem('currentOngoingPhotos') ) || [];
                
                const newPhotos = [
                    {
                      photoType: photoType,
                      imgData: img,
                    },
                    ...currentOngoingPhotos,
                  ];
                  
                localStorage.setItem('currentOngoingPhotos', JSON.stringify(newPhotos) );

                setCurrentOngoingCheckinPhoto(
                    JSON.parse( localStorage.getItem('currentOngoingPhotos') ).find(item => item.photoType === 'checkin') 
                );
                setCurrentOngoingCheckoutPhotoMinute(
                    JSON.parse( localStorage.getItem('currentOngoingPhotos') ).find(item => item.photoType === 'checkout_minute') 
                );
                setCurrentOngoingCheckoutPhotoClient(
                    JSON.parse( localStorage.getItem('currentOngoingPhotos') ).find(item => item.photoType === 'checkout_client') 
                );
            }
        );


    // // Set the preview img src to the object URL and wait for it to load
    // Compress.loadImageElement(preview, objectUrl).then(() => {
    //     // Revoke the object URL to free up memory
    //     URL.revokeObjectURL(objectUrl)
    //     })
    })
        
  }


  return {
    photos,
    currentOngoingPhotos,
    takePhoto,
    currentOngoingCheckinPhoto,
    currentOngoingCheckoutPhotoClient,
    currentOngoingCheckoutPhotoMinute,
    setCurrentOngoingCheckinPhoto,
    setCurrentOngoingCheckoutPhotoClient,
    setCurrentOngoingCheckoutPhotoMinute,
    resize,
  };
}
