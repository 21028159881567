import './OngoingActivity.css';

import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonCheckbox, IonRadioGroup, IonRadio, IonButton, useIonViewWillEnter } from '@ionic/react';

import Card from '../components/Card';
import ActivitiesList from '../components/ActivitiesList';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';

import Timer from '../components/Timer';

const OngoingActivity = () => {

    const history = useHistory();
    
    const [currentOngoingActivity, setCurrentOngoingActivity] = useState(JSON.parse( localStorage.getItem('currentOngoingActivity')) || []);
    //const activity_registered_check_in_time = new Date( localStorage.getItem('activity_registered_check_in_time') );
    const [activity_registered_check_in_time, setActivity_registered_check_in_time] = useState( new Date( localStorage.getItem('activity_registered_check_in_time') ) );
    
    useIonViewWillEnter(() => {
      setCurrentOngoingActivity( JSON.parse( localStorage.getItem('currentOngoingActivity')) || []);
      setActivity_registered_check_in_time( new Date( localStorage.getItem('activity_registered_check_in_time') ) );
    });
    
    

    return (
    <IonPage>
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonBackButton></IonBackButton>
                </IonButtons>
                <IonTitle> Atividade em andamento </IonTitle>    
            </IonToolbar>
        </IonHeader>
      

      <IonContent className='ongoing-activity-content'>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Nova atividade</IonTitle>
          </IonToolbar>
        </IonHeader> */}
        <div className="cbg-container ongoing-activity-container">

            <h1 className='title'> {currentOngoingActivity.post_title} {currentOngoingActivity.client.name} </h1>
            
            <span className='checkin_time'> {activity_registered_check_in_time.getHours()}:{activity_registered_check_in_time.getMinutes()} </span>

            <Timer />

            <button className='bt_encerrar'
                    onClick={(e) => {
                        e.preventDefault();
                        //localStorage.setItem('currentActivity', JSON.stringify(activity));
                        history.push("/home/ongoingActivity/endActivity");
                        }
                    }
                > </button>
                
        </div>

      </IonContent>
    </IonPage>
  );
};

export default OngoingActivity;
