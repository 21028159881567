import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
  useIonViewWillEnter
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { images, square, triangle, home, add, albums} from 'ionicons/icons';
import Home from './pages/Home';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';
import Login from './pages/Login';
import NewActivity from './pages/NewActivity';
import OngoingActivity from './pages/OngoingActivity';
import EndActivity from './pages/EndActivity';
import CreateActivity from './pages/CreateActivity';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/cbg_theme.css'; 
import { useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';

import { AuthContext } from './AuthContext';
import { AuthProvider } from './AuthContext';

import NotFoundPage from './pages/NotFoundPage';
//setupIonicReact();

setupIonicReact({
  //rippleEffect: false,
  mode: 'md',
});

const App = () => {

  const { isAuthed } = useAuth();  
  
  // useEffect( () => {
  //   setIsAuthed( JSON.parse(window.localStorage.getItem('isAuthed')) );
  // }); 

  // useIonViewWillEnter( () => {
  //   setIsAuthed( JSON.parse(window.localStorage.getItem('isAuthed')) );
  // });


  

  const loggedInRouter = (
    <>
          <IonReactRouter>          
            <IonTabs>
              <IonRouterOutlet>

              <Route exact path="/login">
                <Login />
              </Route>
              
              {/* <Route path="/">
                <Home />
              </Route>         */}
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>

              <Route exact path="/home">
                <Home />
              </Route>
              <Route exact path="/createActivity">
                <CreateActivity />
              </Route> 
              <Route path="/tab3">
                <Tab3 />
              </Route>
              <Route exact path="/home/newActivity">
                <NewActivity />
              </Route>
              <Route exact path="/home/ongoingActivity">
                <OngoingActivity />
              </Route>
              <Route exact path="/home/ongoingActivity/endActivity">
                <EndActivity />
              </Route>

              {/** Fallback */}
              
              <Route component={NotFoundPage} />
                
                    {/* <Route exact path="/">
                      <Redirect to="/login" />
                    </Route> */}

              {/* <Route
                exact
                path="/"
                render={() => {
                  return isAuthed ? <Home /> : <Login />;
                }}
              /> */}
              
              </IonRouterOutlet>              
                <IonTabBar slot="bottom">
                  <IonTabButton tab="home" href="/home">
                    <IonIcon aria-hidden="true" icon={home} />                    
                  </IonTabButton>
                  <IonTabButton tab="createActivity" href="/createActivity">
                    <IonIcon aria-hidden="true" icon={add} />                    
                  </IonTabButton>
                  <IonTabButton tab="tab3" href="/tab3">
                    <IonIcon aria-hidden="true" icon={albums} />
                    {/* <IonLabel>Tab 3</IonLabel> */}
                  </IonTabButton>
                </IonTabBar>            
            </IonTabs>
          </IonReactRouter>

      
    </>
  );

  const notLoggedRoutes = (
    <IonReactRouter>        
      <IonRouterOutlet>
        <Route path="/" component={Login} />
      </IonRouterOutlet>
    </IonReactRouter>
  );


  return (

      <IonApp>
        {isAuthed ? loggedInRouter : notLoggedRoutes }      
      </IonApp>
  );
}

export default App;
 