import { useEffect, useState } from 'react';


export function removeActivityFromPendingStack() {
        
    console.log(pendingActivities);
    console.log(currentOngoingActivity);

    let filter = pendingActivities.filter( 
        (el, i) => {
            if(el.ID == currentOngoingActivity.ID){
                return false;
            }else{
                return true;
            }
        }
    );
   
    //localStorage.setItem('currentActivity', false);
    //localStorage.setItem('currentOngoingActivity', false);    
    localStorage.setItem('pendingActivities', JSON.stringify(filter) );

    console.log(JSON.parse( localStorage.getItem('pendingActivities') ) );
    
}


export function updateActivityOnServer(activity) {


    // const [activityPullStack, setActivityPullStack] = useState(localStorage.getItem('token') || []);
    // se online

    //     for each activityPullStack item
    //         faz um update no server com item.ID
    //             se ok 
    //                 remove este item da activityPullStack
    //             senao
                    
    console.log('INIT updateActivityOnServer');

    const token = localStorage.getItem('token');
    const headers = { 
        'Authorization': 'Basic ' + token,
        'Content-Type': 'application/json'
    };    


    //test formdata
    // var form_data = new FormData();
    // for ( var key in item ) {
    //     form_data.append(key, item[key]);
    // }
    
    const activity_update_status = fetch('https://www.catalystbg.com.br/wp-json/catalystbg/v1/activity', { 
        method: 'PUT', 
        headers: headers,
        body: JSON.stringify( activity ),
         
    })
    .then((response) => { 
        console.log(response);
        // if(response.ok){
        //     console.log('response.ok');
        //     console.log('Credenciais validadas.');
        // }else{                
        //     console.log('Login failed.');
        // }
        
        response.json().then((data) => {
            console.log(data); 
            console.log('Parece que deu certo. Vamos remover o item.');
            
            removeItemFromLocalStorageByID('activityPullStack', activity.ID);
        })
    })
    .catch((err) => {
        console.log('Houve um Erro na atualização.');
        console.log(err.message);
    });

    return activity_update_status;
        
 
}


export function processActivityPullStack(){
    
    let items = JSON.parse( localStorage.getItem('activityPullStack') ) || [];

    console.log('processActivityPullStack');

    items.forEach( (item, i) => {
        console.log('processActivityPullStack item: #' + i);
        console.log(item);
        updateActivityOnServer(item);
        
    });


}


export function appendItemToLocalStorage(keyName, value){
    let item = JSON.parse( localStorage.getItem(keyName) );
    item.push(value);

    return localStorage.setItem(keyName, JSON.stringify(item));
}





export function removeItemFromLocalStorageByID(keyName, ID){    
    
    let items = JSON.parse( localStorage.getItem(keyName) );
    console.log(items);
    
    let filtered = items.filter( 
        (el, i) => {
            console.log('el.id=' + el.ID);
            
            console.log('prop.id=' + ID);
            if(el.ID == ID){
                return false;
            }else{
                return true;
            }
        }
    );

   
    console.log(filtered);
    return localStorage.setItem(keyName, JSON.stringify(filtered) );

}





export function activityObjTemplate(){

    const activityObj = {
        'ID':'new',
        //'post_author':'1',
        //'post_content':'',
        //'post_title':'Oracle',
        //'post_status':'publish',
        'client':'',
        'activity_status':'Em andamento',
        'cancellation_reason':'',
        'activity_type':'',
        'activity_category':'',
        'professional':JSON.parse(localStorage.getItem('user')).user_login,
        'activity_check_in_time':'',
        'activity_check_out_time':'',
        'activity_registered_check_in_time':'',
        'activity_registered_check_out_time':'',
        'activity_obs':'',
        'activity_duration':'',
        'activity_registered_duration':'',
        'activity_checkin_photo':'',
        'activity_checkout_photo_minute':'',
        'activity_checkout_photo_client':'',
        'activity_checkout_location':'0,0'
    }



    return activityObj;

}