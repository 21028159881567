import {
    IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonCheckbox, IonRadioGroup, IonRadio, IonButton, IonList,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonImg,
    useIonViewDidEnter,
    useIonViewDidLeave,
    useIonViewWillEnter,
    useIonViewWillLeave,
    useIonAlert,
    IonActionSheet,
    IonAlert,
    IonModal,
    IonInput,
    IonTextarea
} from '@ionic/react';

import { camera, trendingUpOutline, ellipsisHorizontal, ellipsisVertical, closeCircleOutline, closeCircle } from 'ionicons/icons';

import Card from '../components/Card';
import ActivitiesList from '../components/ActivitiesList';
import { useHistory } from 'react-router';
import { useState, useEffect, useRef } from 'react';


import { usePhotoGallery } from '../hooks/usePhotoGallery';
import { useCamera } from '../hooks/useCamera';


const NewActivity = () => {

    const history = useHistory();

    const [currentActivity, setCurrentActivity] = useState(JSON.parse(localStorage.getItem('currentActivity')) || []);
    const [currentOngoingActivity, setCurrentOngoingActivity] = useState(JSON.parse(localStorage.getItem('currentOngoingActivity')) || false);
    const [client, setClient] = useState(JSON.parse(localStorage.getItem('client')) || []);
    const [activityCategory, setActivityCategory] = useState(JSON.parse(localStorage.getItem('activity_category')) || []);
    //const [timerActive, setTimerActive] = useState(JSON.parse( localStorage.getItem('isTimerActive') )    );


    const [selectedActivityType, setSelectedActivityType] = useState('');

    const [location, setLocation] = useState('0,0');

    const { photos, currentOngoingPhotos, takePhoto, currentOngoingCheckinPhoto, setCurrentOngoingCheckinPhoto, resize } = useCamera();

    const [presentAlert] = useIonAlert();

    const [activityPullStack, setActivityPullStack] = useState(
        localStorage.getItem('activityPullStack')
    );
    const [pendingActivities, setPendingActivities] = useState(
        JSON.parse( localStorage.getItem('pendingActivities') )
    );




    useIonViewWillEnter(() => {
        setCurrentActivity(JSON.parse(localStorage.getItem('currentActivity')) || []);
        setClient(JSON.parse(localStorage.getItem('client')) || []);
        setActivityCategory(JSON.parse(localStorage.getItem('activity_category')) || []);
        setCurrentOngoingActivity(JSON.parse(localStorage.getItem('currentOngoingActivity')) || false);
        //setTimerActive(JSON.parse( localStorage.getItem('isTimerActive')) );        

        
        setActivityPullStack( localStorage.getItem('activityPullStack') );
        setPendingActivities( JSON.parse( localStorage.getItem('pendingActivities') ) );    


        setSelectedActivityType(currentActivity.activity_type?.name);

        console.log(currentActivity);


        setCurrentOngoingCheckinPhoto(false);


        // navigator.geolocation.getCurrentPosition(success, error, {
        //     timeout: 4000,
        //     maximumAge: 0
        //   });

        navigator.geolocation.getCurrentPosition((position) => {

            console.log(position);

            // setLocation({
            //     latitude: position.coords.latitude, 
            //     longitude: position.coords.longitude
            // });

            setLocation(position.coords.latitude + ',' + position.coords.longitude);

        } /*, LocationError */
            , (error) => {
                console.warn(`Error: ${error.message}`);
                setLocation('Autorize o serviço de localização em Ajustes do Safari (no  iPhone) ou Chrome/Android');
            });


    });















    
    const addActivityToPullStack = (currentActivity) => {

        console.log('func addActivityToPullStack');



        let now = new Date().toJSON();

        currentActivity.activity_status = 'Cancelado'; 
        currentActivity.activity_registered_check_in_time = now;
        currentActivity.activity_registered_check_out_time = now;
        
        currentActivity.activity_obs = inputOBS.current?.value;
        currentActivity.cancellation_reason = cancelOptions.current?.value;

        currentActivity.activity_checkout_location = location;
        

        currentActivity.activity_category           = currentActivity.activity_category.name; 
        currentActivity.activity_type               = currentActivity.activity_type.name;
        currentActivity.client                      = currentActivity.client.name;
        currentActivity.professional_id             = currentActivity.professional_id.name;

        let stack = JSON.parse(activityPullStack || "[]");
        stack.push(currentActivity);
        localStorage.setItem('activityPullStack', JSON.stringify(stack));


        //limpa variaveis no localStorage
        localStorage.removeItem('currentOngoingPhotos');
        
        
    }

    const removeActivityFromPendingStack = () => {
        
        // console.log(pendingActivities);
        // console.log(currentActivity);

        let filter = pendingActivities.filter( 
            (el, i) => {
                if(el.ID == currentActivity.ID){
                    return false;
                }else{
                    return true;
                }
            }
        );
       
        localStorage.setItem('currentActivity', false);
        localStorage.setItem('currentOngoingActivity', false);    
        localStorage.setItem('pendingActivities', JSON.stringify(filter) );

        console.log('pendingActivities', JSON.parse( localStorage.getItem('pendingActivities') ) );
        
    }
    




    const modal = useRef(null);
    const inputOBS = useRef(null);
    const cancelOptions = useRef(null);

    function confirmModal() {


        console.log(cancelOptions.current?.value);
        console.log(inputOBS.current?.value);

        if (!cancelOptions.current?.value) {

            presentAlert({
                header: 'Atenção',
                //subHeader: 'Important message',
                message: 'Selecione um motivo para o cancelamento',
                buttons: ['OK'],
            })

            return;
        }
        

        addActivityToPullStack(currentActivity);
        removeActivityFromPendingStack(); //Retira imediatamente esta tarefa da listagem da home

        localStorage.setItem('currentOngoingActivity', false);    
        
        history.push("/home");

        // let dataModal = [cancelOptions.current?.value, inputOBS.current?.value];
        // modal.current?.dismiss(dataModal, 'confirm');

        modal.current?.dismiss();

    }

    function onWillDismiss(ev) {
        console.log(ev);
    }


    
    return (
        <IonPage className='form-activities'>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton></IonBackButton>
                    </IonButtons>
                    <IonTitle> Atividade </IonTitle>

                    <IonButtons slot="primary">
                        {/* <IonButton id="alert-cancelamento"> */}
                        <IonButton id="open-modal">
                            <IonIcon slot="icon-only"  ios={closeCircleOutline} md={closeCircleOutline}></IonIcon>  
                        </IonButton>
                    </IonButtons>

                </IonToolbar>
            </IonHeader>


            {/* <IonAlert
                trigger="alert-cancelamento"
                header="Cancelar Atividade"
                subHeader="Selecione um motivo"
                cssClass="alert-cancelamento"
                buttons={[
                    {
                      text: 'Voltar',
                      role: 'cancel',
                      handler: () => {
                        console.log('Alert canceled');
                      },
                    },
                    {
                      text: 'OK',
                      role: 'confirm',
                      handler: (option) => {
                        console.log('Alert confirmed');
                        console.log(option); 



                          //addActivityToPullStack(currentOngoingActivity);
                          removeActivityFromPendingStack(); //Retira imediatamente esta tarefa da listagem da home

                          localStorage.setItem('currentOngoingActivity', false);    
                          
                          history.push("/home");


                      },
                    },
                  ]}
                onDidDismiss={({ detail }) => { 
                    console.log(`Dismissed with role: ${detail.role}`);
                    
                }}

                inputs={[
                {
                    label: 'Cliente: conflito agenda',
                    type: 'radio',
                    value: 'clente_conflito_agenda',
                },
                {
                    label: 'Cliente: não compareceu',
                    type: 'radio',
                    value: 'cliente_nao_compareceu',
                },
                {
                    label: 'Cancelado pelo consultor',
                    type: 'radio',
                    value: 'consultor_cancelou',
                },
                
                ]}
            ></IonAlert> */}




        <IonModal ref={modal} trigger="open-modal" onWillDismiss={(ev) => onWillDismiss(ev)}>
          <IonHeader>
            <IonToolbar>
              {/* <IonButtons slot="start">
                <IonButton onClick={() => modal.current?.dismiss()}>Voltar</IonButton>
              </IonButtons> */}
              <IonTitle>Cancelar Atividade</IonTitle>
              {/* <IonButtons slot="end">
                <IonButton strong={true} onClick={() => confirmModal()}>
                  Confirmar
                </IonButton>
              </IonButtons> */}
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            {/* <IonItem>
              <IonInput
                label="Enter your name"
                labelPlacement="stacked"
                ref={input}
                type="text"
                placeholder="Your name"
              />
            </IonItem> */}

            <IonRadioGroup ref={cancelOptions}>
                <IonItem>
                    <IonRadio value="Cliente: conflito agenda" labelPlacement="start">
                    Cliente: conflito agenda
                    </IonRadio>
                </IonItem>
                <IonItem>
                    <IonRadio value="Cliente: não compareceu" labelPlacement="start">
                    Cliente: não compareceu
                    </IonRadio>
                </IonItem>
                <IonItem>
                    <IonRadio value="Cancelado pelo consultor" labelPlacement="start">
                    Cancelado pelo consultor
                    </IonRadio>
                </IonItem>

                <IonItem>
                    <IonTextarea ref={inputOBS} label="Observações" labelPlacement="stacked" placeholder="Detalhamento opcional"></IonTextarea>
                </IonItem>

            </IonRadioGroup>


            <IonButton expand="block"  size="large" color="danger"  onClick={() => confirmModal()}>
                Cancelar Atividade
            </IonButton>

            <IonButton  expand="block" color="light" onClick={() => modal.current?.dismiss()}>Voltar</IonButton>

          </IonContent>
        </IonModal>



            <IonContent>
                {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Nova atividade</IonTitle>
          </IonToolbar>
        </IonHeader> */}


                <IonItem>
                    <h1 className='title'> {currentActivity.post_title} | {currentActivity.client?.name} </h1>

                </IonItem>
                {/* <IonItem>
                <select name="select" defaultValue={currentActivity.client[0].slug} >
                    {client.map( (client) => {
                        return( <option value={client.slug} key={client.term_id}> {client.name} </option>)
                    })}    
                </select>
            </IonItem> */}



                <div className='mapTarget'>
                    <iframe
                        width="600"
                        height="300"
                        loading="lazy"
                        src={"https://www.google.com/maps/embed/v1/view?key=AIzaSyCVt0_ens_EHfd20lKQcpJYYVv-uJYr8UI&zoom=18&center=" + location}>
                        {/* src={"https://www.google.com/maps/embed/v1/search?key=AIzaSyCVt0_ens_EHfd20lKQcpJYYVv-uJYr8UI&zoom=16&q=" + encodeURI(currentActivity.post_title) + "&center=" + location.latitude + "," + location.longitude}> */}

                    </iframe>

                    <span className='detalhes'> {location} </span>
                </div>



                {/* <IonList>
                <IonItem>
                    <IonSelect 
                        label="Tipo" 
                        labelPlacement="floating" 
                        name='activity_type'
                        value={currentActivity.activity_type?.name}
                        id="new_activity_type_select"
                        >
                            <IonSelectOption value="Externa"> Externa </IonSelectOption>
                            <IonSelectOption value="Interna"> Interna </IonSelectOption>   
                            <IonSelectOption value="Online"> Online </IonSelectOption>   
                    
                    </IonSelect>
                </IonItem>
            </IonList> */}

                <IonList>
                    <IonItem>
                        <IonSelect
                            label="Tipo"
                            labelPlacement="floating"
                            name='activity_type'
                            //value={currentActivity.activity_category[0].term_id}
                            value={selectedActivityType}
                            onIonChange={e => setSelectedActivityType(e.detail.value)}
                        >
                            <IonSelectOption value="Externa"> Externa </IonSelectOption>
                            <IonSelectOption value="Interna"> Interna </IonSelectOption>
                            <IonSelectOption value="Online"> Online </IonSelectOption>

                        </IonSelect>
                    </IonItem>
                </IonList>






                <div className="item">
                    <IonList>
                        <IonItem>
                            <IonSelect
                                label="Categoria"
                                labelPlacement="floating"
                                //multiple={true}
                                value={currentActivity.activity_category?.name || currentActivity.activity_category}

                                onIonChange={(e) => currentActivity.activity_category = e.detail.value}

                            >

                                {
                                    activityCategory.map((item) => {
                                        return (
                                            <IonSelectOption value={item.name} key={item.name}>{item.name}</IonSelectOption>
                                        )
                                    })
                                }

                            </IonSelect>
                        </IonItem>
                    </IonList>
                </div>


                {/* <IonItem>
                <span className='title'> Categoria: </span>
                {currentActivity.activity_category[0].term_id}
            </IonItem> */}






                <div className="foto-checkin-container">
                    <label className="input-photo checkin-photo">
                        <input type="file" id="upload" capture="environment" accept="image/*"
                            onChange={(e) => resize(e.target, 'checkin')}
                        />
                        {!currentOngoingCheckinPhoto && <IonIcon icon={camera}></IonIcon>}
                        {!currentOngoingCheckinPhoto && <span> Foto de Check-In </span>}


                        {currentOngoingCheckinPhoto &&
                            <img src={currentOngoingCheckinPhoto.imgData} />}

                    </label>
                </div>






                <IonItem className='ion-margin-top'>
                    <div className='buttonGroup'>


                        {(!currentOngoingActivity) ?

                            <button
                                onClick={(e) => {
                                    e.preventDefault();

                                    let activityCategorySelected = currentActivity.activity_category;
                                    let checkinPhotoSelected = JSON.parse(localStorage.getItem('currentOngoingPhotos'))?.find(item => item.photoType === 'checkin') ?? false;
                                    //let new_activity_type_select = document.getElementById('new_activity_type_select').value;
                                    let new_activity_type_select = selectedActivityType;

                                    console.log(activityCategorySelected,
                                        checkinPhotoSelected,
                                        new_activity_type_select);


                                    if (!!activityCategorySelected && !!checkinPhotoSelected && !!new_activity_type_select) {


                                        let activity_registered_check_in_time = new Date().toJSON();
                                        currentActivity['activity_checkin_location'] = location;
                                        localStorage.setItem('activity_registered_check_in_time', activity_registered_check_in_time);

                                        localStorage.setItem('currentOngoingActivity', JSON.stringify(currentActivity));

                                        history.push("/home/ongoingActivity");

                                    } else {

                                        presentAlert({
                                            header: 'Atenção',
                                            //subHeader: 'Important message',
                                            message: 'Preencha todos os campos',
                                            buttons: ['OK'],
                                        })

                                    }


                                }
                                }
                            >
                                Iniciar atividade
                            </button>

                            :
                            <button disabled={true}
                            >
                                Iniciar atividade
                            </button>

                        }

                        <button className='red'
                            onClick={() => {
                                history.goBack();
                            }
                            }
                        >
                            Voltar
                        </button>
                    </div>
                </IonItem>




            </IonContent>
        </IonPage>
    );
};

export default NewActivity;
