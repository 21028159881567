import { useIonViewWillEnter } from '@ionic/react';
import { useState, useEffect } from "react";


const Timer = () => {

    const [startTime, setStartTime] = useState( new Date(localStorage.getItem('activity_registered_check_in_time')).getTime() );
    useIonViewWillEnter(() => {
        setStartTime( new Date( localStorage.getItem('activity_registered_check_in_time')) );   
        setCounter( new Date().getTime() - startTime );     
    });

    const [counter, setCounter] = useState(
        new Date().getTime() - startTime
    );
    useEffect(() => {
        setCounter( new Date().getTime() - startTime );
        const interval = setInterval(() => {
            setCounter( new Date().getTime() - startTime );
        }, 1000);

        return () => clearInterval(interval);
    }, [counter]);

    // useEffect(() => {
    //     const interval = () => {
    //         setCounter( new Date().getTime() - startTime );
    //         setTimeout(interval, 1000);
    //     }
    //     interval();

    //     return () => clearTimeout(interval);
    // }, [counter]);

    const getReturnValues = (counter) => {
        
        //const days = Math.floor(counter / (1000 * 60 * 60 * 24));
        let hours = Math.floor(
            (counter % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        hours = (0 == hours) ? '': hours + ':';
        let minutes = Math.floor((counter % (1000 * 60 * 60)) / (1000 * 60));
        minutes = (minutes < 10) ? '0' + minutes + ':' :  minutes + ':';
        let seconds = Math.floor((counter % (1000 * 60)) / 1000);
        seconds = (seconds < 10) ? '0' + seconds : seconds;
        
        
        return [hours, minutes, seconds];
    };



    return(
        <span className='timer time_counter'>             
                
                { getReturnValues(counter)[0]}

                { getReturnValues(counter)[1]}

                { getReturnValues(counter)[2]}                
        </span>
    )
}

export default Timer;
