import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewWillEnter,
} from "@ionic/react";

import { useState } from "react";

import "./Tab3.css";
import PhotoCapture from "./PhotoCaptureTESTE";

const Tab3: React.FC = () => {
  const [activityPullStack, setActivityPullStack] = useState(
    JSON.parse(localStorage.getItem("activityPullStack")) || []
  );

  useIonViewWillEnter(() => {
    setActivityPullStack(
      JSON.parse(localStorage.getItem("activityPullStack")) || []
    );
  });

function getStorageEstimate() {
  try {
    navigator.storage.estimate().then((estimate) => {
      document.getElementById("percent").value = (
        (estimate.usage / estimate.quota) *
        100
      ).toFixed(2);
      document.getElementById("quota").value =
        (estimate.quota / 1024 / 1024).toFixed(2) + "MB";
    });
  } catch (e) {
    console.log("Your browser doesn't support navigator.storage.estimate()");
  }
} // getStorageEstimate();
getStorageEstimate();

navigator.storage.persist();

if (navigator.storage && navigator.storage.persist) {
  navigator.storage.persist().then((persistent) => {
    if (persistent) {
      console.log("Storage will not be cleared except by explicit user action");
    } else {
      console.log("Storage may be cleared by the UA under storage pressure.");
    }
  });
}



  
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Status</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Status</IonTitle>
          </IonToolbar>
        </IonHeader>
{/* 
        <label>
          You're currently using about <output id="percent"> </output>% of your
          estimated storage quota (<output id="quota"></output>).
        </label>

        <PhotoCapture />

 */}

        {activityPullStack.map((item) => {
          console.log("item", item);

          return <div key={item.ID}> {item.post_title} </div>;
        })}

        {(!activityPullStack || activityPullStack.length == 0) && (
          <p> Dados atualizados.</p>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Tab3;
