import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton, IonCheckbox, IonRadioGroup, IonRadio, IonButton, IonList,
    IonItem,
    IonSelect,
    IonInput,
    IonSelectOption,
    IonGrid,
    IonRow,
    IonCol,
    IonIcon,
    IonImg,
    useIonViewDidEnter,
    useIonViewDidLeave,
    useIonViewWillEnter,
    useIonViewWillLeave,
    useIonAlert, IonLabel, IonModal   } from '@ionic/react';
import { camera } from 'ionicons/icons';

import AppTypeahead from '../components/AppTypeahead';

import Card from '../components/Card';
import ActivitiesList from '../components/ActivitiesList';
import { useHistory } from 'react-router';
import { useState, useEffect, useRef } from 'react';


import { usePhotoGallery } from '../hooks/usePhotoGallery';
import { useCamera } from '../hooks/useCamera';

import { activityObjTemplate } from '../hooks/functions';


const CreateActivity = () => {

    const history = useHistory();
    
    const [currentActivity, setCurrentActivity] = useState( [] );
    const [currentOngoingActivity, setCurrentOngoingActivity] = useState( JSON.parse( localStorage.getItem('currentOngoingActivity')) || false );
    const [client, setClient] = useState( JSON.parse( localStorage.getItem('client')) || []);
    const [activityCategory, setActivityCategory] = useState( JSON.parse( localStorage.getItem('activity_category')) || [] );    
    //const [timerActive, setTimerActive] = useState(JSON.parse( localStorage.getItem('isTimerActive') )    );



    const filteredClientsList = 
        client.filter((item) => {
            //return item.name.includes('10');
            return item.situacao !== 'inativa';
          });

          
    // const clientNames = Array();
    // client.map( (thisClient) => {
    //     //console.log(client);
    //     if(thisClient.situacao == 'inativa') return;
    //     clientNames.push(thisClient.name);      
    //   });
    

    console.log('filteredClientsList', filteredClientsList);

    const [presentAlert] = useIonAlert();

    const [location, setLocation] = useState('0,0');

    const { photos, currentOngoingPhotos, takePhoto, currentOngoingCheckinPhoto, setCurrentOngoingCheckinPhoto, resize } = useCamera();



    
    const [selectedActivityType, setSelectedActivityType] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');

    const [selectedClient, setSelectedClient] = useState('');
    const clientSelectionChanged = (client) => {

        console.log('client', client);
        setSelectedClient(client);
        modal.current?.dismiss();
    }

    const modal = useRef(null);



    
    

    let activityTemplate = activityObjTemplate();



    console.log('activityTemplate');
    console.log(activityTemplate);

    useIonViewWillEnter(() => {
        setCurrentActivity( [] );
        setClient( JSON.parse( localStorage.getItem('client')) || []);
        setActivityCategory( JSON.parse( localStorage.getItem('activity_category')) || []);  
        setCurrentOngoingActivity( JSON.parse( localStorage.getItem('currentOngoingActivity') ) || false);
        //setTimerActive(JSON.parse( localStorage.getItem('isTimerActive')) );        
        setCurrentOngoingCheckinPhoto(false);
        document.getElementById('formCreateActivity').reset();

        setSelectedCategory('');
        setSelectedClient('');
        setSelectedActivityType('');
        
        console.log('currentActivity', currentActivity);
        console.log('client', client);

        // navigator.geolocation.getCurrentPosition(success, error, {
        //     timeout: 4000,
        //     maximumAge: 0
        //   });

        navigator.geolocation.getCurrentPosition((position) => {

            console.log(position);
            
            setLocation(position.coords.latitude + ',' + position.coords.longitude);

          } /*, LocationError */
          , (error) => {
            console.warn(`Error: ${error.message}`);
            setLocation('Autorize o serviço de localização em Ajustes do Safari (no  iPhone) ou Chrome');
          });


    });




  return (
    <IonPage className='form-activities'>
      <IonHeader>
        <IonToolbar>
            <IonButtons slot="start">
                <IonBackButton></IonBackButton>
            </IonButtons>
            <IonTitle> Atividade </IonTitle>                
        </IonToolbar>
      </IonHeader>
      

      <IonContent>
        {/* <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Nova atividade</IonTitle>
          </IonToolbar>
        </IonHeader> */}

       
            {/* <IonItem>
                <select name="select" defaultValue={currentActivity.client[0].slug} >
                    {client.map( (client) => {
                        return( <option value={client.slug} key={client.term_id}> {client.name} </option>)
                    })}    
                </select>
            </IonItem> */}

            
        <form id="formCreateActivity" onSubmit={(e) => {
            e.preventDefault();


            //  console.log(e.target.activity_type.value);
            //return;
            
            let activity_registered_check_in_time = new Date().toJSON();
            
            activityTemplate.activity_registered_check_in_time = activity_registered_check_in_time;
            
            activityTemplate.activity_checkin_location = location;

            activityTemplate.post_title = e.target.post_title.value;
            //activityTemplate.client = e.target.client.value;
            activityTemplate.client = selectedClient;
            
            activityTemplate.activity_type = e.target.activity_type.value;
            activityTemplate.activity_category = e.target.activity_category.value;
            //activityTemplate.activity_checkin_photo = e.target.activity_checkin_photo.value;

            let checkinPhotoSelected = JSON.parse( localStorage.getItem('currentOngoingPhotos') )?.find(item => item.photoType === 'checkin') ?? false ;
            //let new_activity_type_select = document.getElementById('new_activity_type_select').value;



            if( 
                !!!activityTemplate.activity_category ||
                !!!checkinPhotoSelected ||
                !!!activityTemplate.activity_type ||
                !!!activityTemplate.post_title ||
                !!!activityTemplate.client
                ){  
                    presentAlert({
                        header: 'Atenção',
                        //subHeader: 'Important message',
                        message: 'Preencha todos os campos',
                        buttons: ['OK'],
                    });

                    return;


            }
            
            localStorage.setItem('activity_registered_check_in_time', activity_registered_check_in_time);
            localStorage.setItem('currentOngoingActivity', JSON.stringify(activityTemplate));

            
            e.target.reset();
            setSelectedClient('');
            setSelectedCategory('');
            setSelectedActivityType('');

            
            console.log('activityTemplate=',activityTemplate);

            
            history.push("/home/ongoingActivity");




            // if( 
            //     !!activityTemplate.activity_category && 
            //     !!checkinPhotoSelected && 
            //     !!activityTemplate.activity_type &&
            //     activityTemplate.post_title &&
            //     activityTemplate.client
            //     ){
            
            //     history.push("/home/ongoingActivity");

            // }else{

            //     presentAlert({
            //         header: 'Atenção',
            //         //subHeader: 'Important message',
            //         message: 'Preencha todos os campos',
            //         buttons: ['OK'],
            //     })

            // }

/************************** */
            


            }
        }>

                
            <div className='mapTarget'>
                <iframe
                    width="600"
                    height="300"
                    loading="lazy"
                    src={"https://www.google.com/maps/embed/v1/view?key=AIzaSyCVt0_ens_EHfd20lKQcpJYYVv-uJYr8UI&zoom=18&center=" + location}>
                    {/* src={"https://www.google.com/maps/embed/v1/search?key=AIzaSyCVt0_ens_EHfd20lKQcpJYYVv-uJYr8UI&zoom=16&q=" + encodeURI(currentActivity.post_title) + "&center=" + location.latitude + "," + location.longitude}> */}
                
                </iframe>

                <span className='detalhes'> {location} </span>
            </div>


            <IonItem>
                <IonInput label="Título" name="post_title" placeholder="Título da atividade"></IonInput>
            </IonItem>


            {/* <IonItem>
                <IonInput label="Cliente" name="client" placeholder="Digite o nome da empresa"></IonInput>
            </IonItem> */}

            

            

            {/* <div className="item">
                <IonList>
                    <IonItem>
                        <IonSelect 
                            label="Cliente" 
                            labelPlacement="floating" 
                            multiple={false}
                            name="client____"
                            //value={client.term_id}
                            >

                            {
                                client.map( (item) => {
                                    return (
                                        <IonSelectOption value={item.name} key={item.term_id}>{item.name}</IonSelectOption>        
                                    )
                                })
                            }
                            
                        </IonSelect>
                    </IonItem>
                </IonList>
            </div> */}


            <div className="item">
                
                <IonList>
                    <IonItem button={true} detail={false} id="select-client">
                        <IonLabel>Cliente</IonLabel>
                        <div slot="end" id="selected-client">
                        {selectedClient}
                        </div>
                    </IonItem>
                </IonList>            

                <IonModal trigger="select-client" ref={modal}>
                    <AppTypeahead
                        title="Clientes"
                        items={filteredClientsList}
                        selectedItem={selectedClient}
                        onSelectionCancel={() => modal.current?.dismiss()}
                        onSelectionChange={clientSelectionChanged}
                    />
                </IonModal>
            </div>
            

            

            <IonList>
                <IonItem>
                    <IonSelect 
                        label="Tipo" 
                        labelPlacement="floating" 
                        name='activity_type'
                        //value={currentActivity.activity_category[0].term_id}
                        value={selectedActivityType}
                        onIonChange={ e => setSelectedActivityType(e.detail.value) }
                        >
                            <IonSelectOption value="Externa"> Externa </IonSelectOption>
                            <IonSelectOption value="Interna"> Interna </IonSelectOption>   
                            <IonSelectOption value="Online"> Online </IonSelectOption>   
                    
                    </IonSelect>
                </IonItem>
            </IonList>
            
            {/* <h3 className='item-title'> Tipo </h3>
            <IonItem>                
                <IonRadioGroup name="activity_type" >                                                
                    <IonRadio labelPlacement="end" value="Externa"> Externa </IonRadio>
                    <IonRadio labelPlacement="end" value="Interna"> Interna </IonRadio>
                    <IonRadio labelPlacement="end" value="Online"> Online </IonRadio>                    
                </IonRadioGroup>
            </IonItem> */}

 

            <div className="item">
                <IonList>
                    <IonItem>
                        <IonSelect 
                            label="Categoria" 
                            labelPlacement="floating" 
                            //multiple={true}
                            name='activity_category'
                            //value={currentActivity.activity_category[0].term_id}
                            value={selectedCategory}
                            onIonChange={ e => setSelectedCategory(e.detail.value)}
                            >

                            {
                                activityCategory.map( (item) => {
                                    return (
                                        <IonSelectOption value={item.name} key={item.name}>{item.name}</IonSelectOption>        
                                    )
                                })
                            }
                            
                        </IonSelect>
                    </IonItem>
                </IonList>
            </div>
          


            <div className="foto-checkin-container">
                <label className="input-photo checkin-photo">
                        <input type="file" id="upload" capture="environment" accept="image/*" 
                            onChange={(e) => resize(e.target, 'checkin')}
                        />
                    <IonIcon icon={camera}></IonIcon>
                    <span> Foto de Check-In </span>

                    { currentOngoingCheckinPhoto &&
                        <img src={currentOngoingCheckinPhoto.imgData} /> }

                </label>                    
            </div>

            

            <IonItem className='ion-margin-top'>
                <div className='buttonGroup'>
                   
                    <button type='submit' disabled={!!currentOngoingActivity}> 
                        Iniciar atividade 
                    </button>

                    <button type='button' className='red'
                        onClick={() => {
                            //history.goBack();
                            history.push("/home");
                            }
                        }
                    > 
                        Voltar 
                    </button>
                </div>
            </IonItem>

   
        </form>

      </IonContent>
    </IonPage>
  );
};

export default CreateActivity;
