import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, 
    IonBackButton, IonCheckbox, IonRadioGroup, IonRadio, IonButton, IonItem, 
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonSelect,
    IonTextarea,
    IonIcon,
    IonSelectOption,
    useIonViewWillEnter, 
    useIonAlert } from '@ionic/react';

    import { camera } from 'ionicons/icons';
import Card from '../components/Card';
import ActivitiesList from '../components/ActivitiesList';
import { useHistory } from 'react-router';
import { useState } from 'react';


import { useCamera } from '../hooks/useCamera';


const EndActivity = () => {

    const history = useHistory();


    const [activityPullStack, setActivityPullStack] = useState(
        localStorage.getItem('activityPullStack')
    );
    const [currentOngoingActivity, setCurrentOngoingActivity] = useState(
        JSON.parse( localStorage.getItem('currentOngoingActivity') )
    );
    const [pendingActivities, setPendingActivities] = useState(
        JSON.parse( localStorage.getItem('pendingActivities') )
    );

    const [activityCategory, setActivityCategory] = useState( JSON.parse( localStorage.getItem('activity_category')) );    

    //const [activityType, setActivityType] = useState();
    const [selectedActivityType, setSelectedActivityType] = useState('');

    
    const [activity_registered_check_in_time, setActivity_registered_check_in_time] = useState( localStorage.getItem('activity_registered_check_in_time') );
    const [activity_registered_check_out_time, setActivity_registered_check_out_time] = useState( new Date().toJSON() );
    const [activity_registered_duration, setActivity_registered_duration] = useState( (new Date(activity_registered_check_out_time) - new Date(activity_registered_check_in_time) ) / 1000 / 60);
        
    const [obs, setObs] = useState('');

    const [location, setLocation] = useState('0,0');

    
    const [presentAlert] = useIonAlert();

    //const [activityType, setActivityType] = useState(JSON.parse(localStorage.getItem('activity_type')));

    //const { currentOngoingPhotos, takePhoto, currentOngoingCheckinPhoto } = useCamera();
    const { photos, currentOngoingPhotos, takePhoto, 
        currentOngoingCheckinPhoto, 
        currentOngoingCheckoutPhotoClient,
        currentOngoingCheckoutPhotoMinute,        
        setCurrentOngoingCheckinPhoto,
        setCurrentOngoingCheckoutPhotoClient,
        setCurrentOngoingCheckoutPhotoMinute,
        resize } = useCamera();

    
        //client, category, type

    useIonViewWillEnter(() => {
        setActivityPullStack( localStorage.getItem('activityPullStack') );
        setCurrentOngoingActivity( JSON.parse( localStorage.getItem('currentOngoingActivity') ) );        
        setPendingActivities( JSON.parse( localStorage.getItem('pendingActivities') ) );    


        setActivity_registered_check_out_time();
        setActivity_registered_duration();

        setSelectedActivityType(currentOngoingActivity.activity_type?.name);


        setCurrentOngoingCheckoutPhotoClient(false);
        setCurrentOngoingCheckoutPhotoMinute(false)

        setActivity_registered_check_in_time(localStorage.getItem('activity_registered_check_in_time') );
        setActivity_registered_check_out_time( new Date().toJSON() );
        setActivity_registered_duration( (new Date(activity_registered_check_out_time) - new Date(activity_registered_check_in_time) ) / 1000 / 60 );



        setSelectedActivityType(currentOngoingActivity.activity_type?.name || currentOngoingActivity.activity_type);

        
        setObs('');
        
        console.log('activity_registered_check_in_time', activity_registered_check_in_time);
        console.log('activity_registered_check_out_time', activity_registered_check_out_time);
        console.log('activity_registered_duration', activity_registered_duration);
        


        navigator.geolocation.getCurrentPosition((position) => {

            console.log(position);
            
            setLocation(position.coords.latitude +',' + position.coords.longitude);

          } /*, LocationError */);


    });

    const addActivityToPullStack = (currentOngoingActivity) => {

        console.log('func addActivityToPullStack');

        currentOngoingActivity.activity_registered_check_in_time = activity_registered_check_in_time;
        currentOngoingActivity.activity_registered_check_out_time = activity_registered_check_out_time;
        currentOngoingActivity.activity_registered_duration = (new Date(activity_registered_check_out_time) - new Date(activity_registered_check_in_time) ) / 1000 / 60;
        currentOngoingActivity.activity_status = 'Finalizado'; 
        currentOngoingActivity.activity_obs = obs;
        currentOngoingActivity.activity_checkout_location = location;
        currentOngoingActivity.activity_checkin_photo = JSON.parse( localStorage.getItem('currentOngoingPhotos') ).find(item => item.photoType === 'checkin') ;
        currentOngoingActivity.activity_checkout_photo_minute = JSON.parse( localStorage.getItem('currentOngoingPhotos') ).find(item => item.photoType === 'checkout_minute') ;
        currentOngoingActivity.activity_checkout_photo_client = JSON.parse( localStorage.getItem('currentOngoingPhotos') ).find(item => item.photoType === 'checkout_client') ;
        currentOngoingActivity.activity_type = JSON.parse( localStorage.getItem('currentOngoingActivity') ).activity_type?.name || currentOngoingActivity.activity_type;
        
        currentOngoingActivity.client = JSON.parse( localStorage.getItem('currentOngoingActivity') ).client?.name || currentOngoingActivity.client ;
        
        currentOngoingActivity.activity_category = JSON.parse( localStorage.getItem('currentOngoingActivity') ).activity_category?.name || currentOngoingActivity.activity_category;

        

        console.log('currentOngoingCheckinPhoto');
        console.log(currentOngoingCheckinPhoto);
        

        // 'activity_duration' => $activity_duration, 
        

        let stack = JSON.parse(activityPullStack || "[]");
        stack.push(currentOngoingActivity);
        localStorage.setItem('activityPullStack', JSON.stringify(stack));


        //limpa variaveis no localStorage
        localStorage.removeItem('currentOngoingPhotos');
        
        
    }

    const removeActivityFromPendingStack = () => {
        
        console.log(pendingActivities);
        console.log(currentOngoingActivity);

        let filter = pendingActivities.filter( 
            (el, i) => {
                if(el.ID == currentOngoingActivity.ID){
                    return false;
                }else{
                    return true;
                }
            }
        );
       
        //localStorage.setItem('currentActivity', false);
        //localStorage.setItem('currentOngoingActivity', false);    
        localStorage.setItem('pendingActivities', JSON.stringify(filter) );

        console.log(JSON.parse( localStorage.getItem('pendingActivities') ) );
        
    }
    

  return (
    <IonPage className='form-activities'>
      <IonHeader>
        <IonToolbar>
            <IonButtons slot="start">
                <IonBackButton></IonBackButton>
            </IonButtons>
            <IonTitle> Finalizar atividade </IonTitle>    
        </IonToolbar>
      </IonHeader>
      

      <IonContent >

        
      <div className="cbg-container ">
            <IonItem>
                <h1 className='title'> {currentOngoingActivity.post_title} {currentOngoingActivity.client?.name} </h1>
            </IonItem> 

            {/* <IonItem>
                <select name="select" value={currentActivity.client[0].slug} >
                    {client.map( (client) => {
                        return( <option value={client.slug} key={client.term_id}> {client.name} </option>)
                    })}    
                </select>
            </IonItem> */}

            
                
            <div className='mapTarget'>
                <iframe
                    width="600"
                    height="300"
                    loading="lazy"
                    src={"https://www.google.com/maps/embed/v1/view?key=AIzaSyCVt0_ens_EHfd20lKQcpJYYVv-uJYr8UI&zoom=18&center=" + location}>
                    {/* src={"https://www.google.com/maps/embed/v1/search?key=AIzaSyCVt0_ens_EHfd20lKQcpJYYVv-uJYr8UI&zoom=16&q=" + encodeURI(currentActivity.post_title) + "&center=" + location.latitude + "," + location.longitude}> */}
                
                </iframe>

                <span className='detalhes'> {location} </span>


            </div>



            {/* <IonList>
                <IonItem>
                    <IonSelect 
                        label="Tipo" 
                        labelPlacement="floating" 
                        name='activity_type'
                        id='selectActivityType'
                        value={currentOngoingActivity.activity_type?.name || currentOngoingActivity.activity_type}
                        >
                            <IonSelectOption value="Externa"> Externa </IonSelectOption>
                            <IonSelectOption value="Interna"> Interna </IonSelectOption>   
                            <IonSelectOption value="Online"> Online </IonSelectOption>   
                    
                    </IonSelect>
                </IonItem>
            </IonList> */}
            

            
            <IonList>
                <IonItem>
                    <IonSelect 
                        label="Tipo" 
                        labelPlacement="floating" 
                        name='activity_type'
                        //value={currentActivity.activity_category[0].term_id}
                        value={selectedActivityType}
                        onIonChange={ e => setSelectedActivityType(e.detail.value) }
                        >
                            <IonSelectOption value="Externa"> Externa </IonSelectOption>
                            <IonSelectOption value="Interna"> Interna </IonSelectOption>   
                            <IonSelectOption value="Online"> Online </IonSelectOption>   
                    
                    </IonSelect>
                </IonItem>
            </IonList>
            




            <div className="item">
                <IonList>
                    <IonItem>
                        <IonSelect 
                            label="Categoria" 
                            labelPlacement="floating" 
                            //multiple={true}
                            value={currentOngoingActivity.activity_category?.name || currentOngoingActivity.activity_category}>

                            {
                                activityCategory.map( (item) => {
                                    return (
                                        <IonSelectOption value={item.name} key={item.name}>{item.name}</IonSelectOption>        
                                    )
                                })
                            }
                            
                        </IonSelect>
                    </IonItem>
                </IonList>
            </div>



            <h2 className='item-title'> Anexos </h2>

            <div className="foto-checkout-container">

                <label className="input-photo checkin-photo">
                        <input type="file" capture="environment" accept="image/*" 
                            onChange={(e) => resize(e.target, 'checkout_client')}
                        />
                    <IonIcon icon={camera}></IonIcon>
                    <span> Foto Empresa </span>

                    { currentOngoingCheckoutPhotoClient &&
                        <img src={currentOngoingCheckoutPhotoClient.imgData} /> }

                </label>   


                <label className="input-photo checkin-photo">
                        <input type="file" capture="environment" accept="image/*" 
                            onChange={(e) => resize(e.target, 'checkout_minute')}
                        />
                    <IonIcon icon={camera}></IonIcon>
                    <span> Ata de reunião </span>

                    { currentOngoingCheckoutPhotoMinute &&
                        <img src={currentOngoingCheckoutPhotoMinute.imgData} /> }

                </label>  

                
    
            </div> 

            

            <IonItem>

                <IonTextarea 
                    label="Observações" 
                    labelPlacement="floating" 
                    placeholder="Obs"
                    autoGrow={true}   
                    value={obs}
                    onIonInput={(e) => {
                        setObs(e.detail.value);
                        console.log('obs:' + obs);

                        }   
                    }
                >
                    
                </IonTextarea>

            </IonItem>
            
            <IonButton 
                expand="block"
                onClick={(e) => {
                    e.preventDefault();
                    
                    //setActivityType(document.getElementById('selectActivityType').value);
                                
                    let CheckoutPhotoClientSelected = JSON.parse( localStorage.getItem('currentOngoingPhotos') )?.find(item => item.photoType === 'checkout_client') ?? false ;
                    let CheckoutPhotoMinuteSelected = JSON.parse( localStorage.getItem('currentOngoingPhotos') )?.find(item => item.photoType === 'checkout_minute') ?? false ;

                        if( !!CheckoutPhotoClientSelected && !!CheckoutPhotoMinuteSelected ){

                                //localStorage.setItem('currentActivity', JSON.stringify(activity));
                                addActivityToPullStack(currentOngoingActivity);
                                removeActivityFromPendingStack(); //Retira imediatamente esta tarefa da listagem da home

                                localStorage.setItem('currentOngoingActivity', false);    
                                
                                history.push("/home");

                        }else{

                            presentAlert({
                                header: 'Atenção', 
                                //subHeader: 'Important message',
                                message: 'Realize as fotografias',
                                buttons: ['OK'],
                            })

                        }

                    
                    }   
                }
            > Encerrar atividade </IonButton>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default EndActivity;
